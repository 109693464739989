import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../components/mdrender";
import * as md from "../components/mdrender.module.sass";
import TitleBlock from "../components/title";

import "./merit@gen.scss";

const GeneratedMerit = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>Merit page</title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="Digital Stacksは、PagerDutyの国内初の正規販売代理店です。
日本でのPagerDuty導入実績No.1弊社契約のメリットをご紹介します。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="Digital Stacksは、PagerDutyの国内初の正規販売代理店です。
日本でのPagerDuty導入実績No.1弊社契約のメリットをご紹介します。"
        />

        <meta property="og:title" content="Merit page" />

        <meta
          property="og:description"
          content="Digital Stacksは、PagerDutyの国内初の正規販売代理店です。
日本でのPagerDuty導入実績No.1弊社契約のメリットをご紹介します。"
        />
        <meta property="og:url" content="https://pagerduty.dxable.com/merit/" />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/merit/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Why DSC?"
        textGreen="Digital Stacksが選ばれるポイント"
        overview="Digital Stacksは、PagerDutyの国内初の正規販売代理店です。<br/>日本でのPagerDuty導入実績No.1弊社契約のメリットをご紹介します。"
      />

      <div className="meritWrapper">
        <div className="merit-container parts-container">
          <div className="text-left-1 mt0">
            <div className="text-part">
              <h4 className="text-left-title">
                <span className="index">01.</span>
                <br />
                日本語での一次サポートを受けられます
              </h4>
              <p className="text-left-para">
                サービスのご利用に関しての基本的な疑問・質問に関しては、一次サポートとして弊社のエンジニアが日本語でお受けします。また、ご契約企業の社員の方は、弊社作成の日本語のサポート資料などを掲載している「
                <a href="https://pagerduty-digitalstacks.zendesk.com/">
                  <span classname="text-[#4EB346]">
                    PagerDuty ヘルプセンター
                  </span>
                </a>
                」に無料でアクセスできます。
              </p>
            </div>
            <div className="img-container">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Merit/merit_img_1_df549c59ae.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672984246196&tr=w-435,h-310,q-100,f-webp"
                alt="INFRASTRUCTURE"
              />
            </div>
          </div>
          <div className="text-right-1">
            <div className="text-part">
              <h4 className="text-right-title">
                <span className="index">02.</span>
                <br />
                支払処理が日本円で、年1回の請求書払いで済みます
              </h4>
              <p className="text-right-para">
                直接契約の場合とは違い、DigitalStacks経由の場合は支払いは日本円で行えます。また年1回の請求書払いが選択できます。為替リスクを請求日のレートで固定できるほか、毎月のクレジットカードでの精算処理が不要です。
              </p>
            </div>
            <div className="img-container">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Merit/merit_img_2_2d31ca9d4a.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672984244408&tr=w-435,h-310,q-100,f-webp"
                alt="INFRASTRUCTURE"
              />
            </div>
          </div>
          <div className="text-left-1 mb-[100px]">
            <div className="text-part">
              <h4 className="text-left-title">
                <span className="index">03.</span>
                <br />
                チームの増員に合わせて、ユーザー数を柔軟に追加できます
              </h4>
              <p className="text-left-para">
                ユーザー数の追加に際しても、必要になった時点でお知らせいただければ通常数日で対応いたします。
              </p>
            </div>
            <div className="img-container">
              <img
                src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Merit/merit_img_3_df7391e92a.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672984244285&tr=w-435,h-310,q-100,f-webp"
                alt="INFRASTRUCTURE"
              />
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedMerit;
